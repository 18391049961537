<template>
  <v-layout wrap v-if="editedItem" class="form-menu">
    <v-flex xs12 >
      <v-toolbar
        elevation="0"     
      >
        <v-icon large style="margin-right:10px;">mdi-seat</v-icon>
        <v-toolbar-title>
          {{ $t(isNew ? 'common.create' : 'common.edit', locale) }} {{ $t('booking.resources.title', locale).toLowerCase() }}
        </v-toolbar-title>
      </v-toolbar>
    </v-flex>
    <v-flex xs12>
      <v-layout wrap>
        <v-flex xs12 style="padding:10px 20px 0 20px">
          <translate
            v-model="editedItem.Name"
            :languages="languages"
            :label="$t('booking.resources.name', locale)"
            @input="handleChangeIsModified"
            type="text"
          />
        </v-flex>
        <v-flex xs12 style="padding:20px 20px 0 20px">
          <translate
            v-model="editedItem.Label"
            :languages="languages"
            :label="$t('booking.resources.label', locale)"
            @input="handleChangeIsModified"
            type="text"
          />
        </v-flex>
        <v-flex xs12 style="padding:20px 20px 0 20px">
                <v-checkbox
                  v-model="editedItem.LimitAllDay"
                  :label="$t('booking.resources.limitAllDay', locale)"
                  hide-details
                />
        </v-flex>
        <v-flex xs12 style="padding:20px 20px 0 20px">
                <v-checkbox
                  v-model="editedItem.IsSeatMap"
                  :label="$t('booking.resources.isSeatMap', locale)"
                  hide-details
                />
        </v-flex>
        <v-flex xs12 style="margin-top:20px;padding:10px 0 0 0">
          <resource-items
            :resourcesID="editedItem.ID"
            :seatMapBackground="editedItem.SeatMapBackground"
            :onChange="handleChangeResourceItems"
            :onChangeSeatMapBackground="handleChangeSeatMapBackground"
            :onDeleteSeatMap="handleDeleteSeatMap"
            :locale="locale"
            :isSeatMap="editedItem.IsSeatMap"
          />
        </v-flex>
        <v-flex xs12 style="padding-top:20px">
          <action-buttons
            :id="editedItem.ID"
            :onDeleteItem="handleDelete"
            :onSaveItem="handleSave"
            :onClose="onClose"
            :locale="locale"
          />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import utils from '@/services/utils'
import Translate from '@/components/Translate'
import ActionButtons from '@/components/ActionButtons'
import ResourceItems from './resourceItems/Index'
export default {
  name: 'folder-form',
  components: {
    Translate,
    ActionButtons,
    ResourceItems,
  },
  props: {
    editedID: {
      type: String,
      default: null,
    },
    onClose: {
      type: Function,
      required: true
    },
    newParentID: {
      // used only when creating a new menu
      type: String,
      default: null,
    },
  },
  data () {
    return {
      panel: [],
      image: null,
      editedItem: null,
      showSelectCustomer: false,
    }
  },
  computed: {        
    ...mapState('app', ['locale']),
    languages () {
      return this.$store.state.app.bookingLanguages
    },
    workspaceID () {
      return utils.getWorkspace('booking')
    },
    isNew () {
      return this.editedID === 'new'
    },
  },
  watch: {
    editedID (v) {
      this.panel = this.isNew ? [0] : []
      if (v) this.handleGetItem()
    },
  },
  mounted () {
    this.panel = this.isNew ? [0] : []
    if (this.editedID) this.handleGetItem()
  },
  methods: {
    handleGetItem () {
      if (this.isNew) {
        this.editedItem = {
          WorkspaceID: this.workspaceID,
          Name: {
            es: '',
          },
          Label: {
            es: '',
          },
          IsSeatMap: false,
        }
      } else 
        api.getItem ('booking', 'v1/private/resources/', this.editedID)
          .then(response => {
            if (!response) return
            response.Name = JSON.parse(response.Name)
            response.Label = response.Label ? JSON.parse(response.Label) : {es: ''}
            response.IsSeatMap = response.IsSeatMap ? true : false
            this.editedItem = response
          })
    },
    handleChangeIsModified () {
      this.$store.state.app.dtouchMenuEdit = {
        ...this.editedItem,
        LMD: new Date().getTime()
      }
    },
    handleSave () {
      if (this.isNew)
        api.addItem ('booking', 'v1/private/resources/', this.editedItem)
          .then((response) => {
            this.onClose('add', response.data)
          })
      else 
        api.updateItem ('booking', 'v1/private/resources/', this.editedItem.ID, this.editedItem)
          .then((response) => {
            const id = response.data && response.data.ID ? response.data.ID : null
            this.onClose('update', id)
          })
    },
    handleDelete () {
      api.deleteItem('booking', `v1/private/resources/`, this.editedID)
        .then(response => {
          if(response && response.data.affectedRows > 0) this.onClose('delete', this.editedID)
        })
    },
    handleChangeResourceItems (v) {
      this.editedItem.ResourceItems = v
    },
    handleChangeSeatMapBackground (v) {
      this.editedItem.newSeatMapBackground = v
    },
    handleDeleteSeatMap () {
      api.deleteItem('booking', `v1/private/resources/`, this.editedID.concat('/seat-map'))
        .then(response => {
          console.log('jhm', response)
        })
    },
  },
}
</script>
<style scoped>
.form-menu .common-forms {
  padding: 0 10px 10px 10px;
}
</style>


